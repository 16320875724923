import { useUserContext } from '../providers/UserContextProvider';
import Av8App from './Av8App';
import Dashboard from './Dashboard';

const App = () => {
  const user = useUserContext();

  if (user) {
    return <Dashboard />;
  }

  return <Av8App />;
};
export default App;
