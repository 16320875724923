import { ReactChild, useContext, useEffect, useState } from 'react';
import UserContext from '../contexts/UserContext';
import { CurrentUserQuery, useCurrentUserQuery } from '../types/graphql';
interface UserProviderProps {
  children: ReactChild;
}

const UserContextProvider = ({ children }: UserProviderProps) => {
  const { loading, error, data } = useCurrentUserQuery();
  const [currentUser, setCurrentUser] = useState<CurrentUserQuery['currentUser']>(null);

  useEffect(() => {
    if (!loading && !error && data?.currentUser) {
      setCurrentUser(data.currentUser);
    }
  }, [data, loading, error]);

  return <UserContext.Provider value={currentUser}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must used within a UserContextProvider');
  }

  return context;
};

export default UserContextProvider;
