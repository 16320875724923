// @ts-nocheck
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import PlacesAutoComplete, { DEFAULT_MAP_DATA } from '../common/PlacesAutoComplete';

const OwnerCRUD = () => {
  const [mapData, setMapData] = useState(DEFAULT_MAP_DATA);

  const handleData = (data) => {
    setMapData(data);
  };

  useEffect(() => {
    console.log(mapData);
  }, [mapData]);

  return (
    <Box>
      <Typography>Creating a new owner</Typography>
      <br />
      <PlacesAutoComplete onData={handleData} />
      <p></p>
      <div>
        <TextField variant="filled" label="First name" />
        <TextField variant="filled" label="Last name" />
      </div>
      <div>
        <TextField variant="filled" label="Home" />
        <TextField variant="filled" label="Work" />
        <TextField variant="filled" label="Mobile" />
        <TextField variant="filled" label="Fax" />
      </div>
      <div>
        <TextField variant="filled" label="Address" value={mapData.line1} />
        <TextField variant="filled" label="Address line 2" value={mapData.line2} />
        <TextField variant="filled" label="City" value={mapData.city} />
        <TextField variant="filled" label="State" value={mapData.state} />
        <TextField variant="filled" label="Zipcode" value={mapData.zipcode} />
      </div>
      <div>
        <TextField variant="filled" label="Primary contact?" />
        <TextField variant="filled" label="Notes" multiline rows={4} />
      </div>
    </Box>
  );
};

export default OwnerCRUD;
