// @ts-nocheck
import useOnclickOutside from 'react-cool-onclickoutside';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';

export const DEFAULT_MAP_DATA = {
  line1: '',
  line2: '',
  city: '',
  state: '',
  zipcode: ''
};

const PlacesAutoComplete = ({ onData }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300
  });

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = (resp) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(resp.description, false);
    clearSuggestions();

    const parameter = {
      // Use the "place_id" of suggestion from the dropdown (object), here just taking first suggestion for brevity
      placeId: resp.place_id,
      // Specify the return data that you want (optional)
      fields: ['address_components', 'formatted_address']
    };

    getDetails(parameter)
      .then((details) => {
        const { address_components: address } = details;
        const formattedMapData = {
          line1: `${address[0].long_name} ${address[1].long_name}`,
          city: address[3].long_name,
          state: address[5].short_name,
          zipcode: address[7].long_name,
          _resp: details
        };
        onData(formattedMapData);
      })
      .catch((error) => {
        console.log('Error: ', error);
      });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text }
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div ref={ref}>
      <input value={value} onChange={handleInput} disabled={!ready} placeholder="Address search" />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && <ul>{renderSuggestions()}</ul>}
    </div>
  );
};

export default PlacesAutoComplete;
