import { Button, ButtonProps } from '@mui/material';

const LoginButton = (props: ButtonProps) => {
  return (
    <Button href="/login/auth0" variant="outlined" sx={{ my: 1, mx: 1.5 }}>
      Log In
    </Button>
  );
};

export default LoginButton;
