import { Add } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const Patients = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Button component={Link} to="/dashboard/patient" variant="contained" endIcon={<Add />}>
        Create
      </Button>

      <Typography>Patients</Typography>
    </Box>
  );
};

export default Patients;
