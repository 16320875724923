import { Add } from '@mui/icons-material';
import { Paper } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const Owners = () => {
  return (
    <Paper>
      <Button component={Link} to="/dashboard/owner" variant="contained" endIcon={<Add />}>
        Add new owner
      </Button>

      <Typography>Current</Typography>
    </Paper>
  );
};

export default Owners;
