import { ViewState } from '@devexpress/dx-react-scheduler';
import {
  Appointments,
  DayView,
  Scheduler,
  Toolbar,
  ViewSwitcher,
  WeekView
} from '@devexpress/dx-react-scheduler-material-ui';
import { Paper } from '@mui/material';
import { Box } from '@mui/system';

const currentDate = new Date();
const schedulerData = [
  { startDate: '2018-11-01T09:45', endDate: '2018-11-01T11:00', title: 'Meeting' },
  { startDate: '2018-11-01T12:00', endDate: '2018-11-01T13:30', title: 'Go to a gym' }
];

const Calendar = () => {
  return (
    <Box>
      <Paper>
        <Scheduler data={schedulerData}>
          <ViewState defaultCurrentDate="2018-07-25" defaultCurrentViewName="Week" />

          <DayView startDayHour={9} endDayHour={18} />
          <WeekView startDayHour={10} endDayHour={19} />

          <Toolbar />
          <ViewSwitcher />
          <Appointments />
        </Scheduler>
      </Paper>
    </Box>
  );
};

export default Calendar;
