import AppBar from '@mui/material/AppBar';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useUserContext } from '../../../providers/UserContextProvider';
import LoginButton from './LoginButton';
import Profile from './Profile';

const AppToolbar = () => {
  const authenciatedUser = useUserContext();

  if (!authenciatedUser) {
    return (
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            AV8
          </Typography>
          <nav>
            <Link variant="button" color="text.primary" href="#" sx={{ my: 1, mx: 1.5 }}>
              Features
            </Link>
            <Link variant="button" color="text.primary" href="#" sx={{ my: 1, mx: 1.5 }}>
              Support
            </Link>
          </nav>
          <LoginButton href="#" variant="outlined" sx={{ my: 1, mx: 1.5 }} />
        </Toolbar>
      </AppBar>
    );
  }

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
    >
      <Toolbar sx={{ flexWrap: 'wrap' }}>
        <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
          AV8
        </Typography>
        <Profile />
      </Toolbar>
    </AppBar>
  );
};

export default AppToolbar;
