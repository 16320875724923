import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './apps/App';
import Dashboard from './apps/Dashboard';
import Calendar from './components/calendar/Calendar';
import OwnerCRUD from './components/owners/OwnerCRUD';
import Owners from './components/owners/Owners';
import Patients from './components/Patients.tsx/Patients';
import Records from './components/records/Records';
import Templates from './components/templates/Templates';
import UserContextProvider from './providers/UserContextProvider';
import reportWebVitals from './reportWebVitals';

const client = new ApolloClient({
  uri: '/graphql',
  cache: new InMemoryCache()
});

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
    <ApolloProvider client={client}>
      <UserContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="dashboard" element={<Dashboard />}>
              <Route path="calendar" element={<Calendar />} />
              <Route path="owners" element={<Owners />} />
              <Route path="owner" element={<OwnerCRUD />} />

              <Route path="records" element={<Records />} />
              <Route path="patients" element={<Patients />} />
              <Route path="templates" element={<Templates />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </UserContextProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
